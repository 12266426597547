import PubSub from "vanilla-pubsub";

export default class {
  constructor(selector) {
    this.el = document.querySelector(selector);

    if (this.el) {
      this.bind();
    }
  }

  bind() {
    PubSub.subscribe("App.load", this._onLoad.bind(this));
  }

  _onLoad() {
    const div = document.createElement("div");
    div.style.height = "calc(100% - " + this.el.clientHeight + "px)";
    this.el.parentNode.insertBefore(div, this.el);
  }
}
