import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "@/assets/js/components/Spinner/Spinner";
import API from "@cinra/media-api";

export type ArticleItemProps = {
  data: any;
};
export type ArticleListTwoColumnProps = { slug: string };

const ArticleItem = ({ data }: ArticleItemProps) => {
  const isSubmedia = !!data.submedia;

  const url = isSubmedia ? data.url : `/${data.type}/${data.slug}`;
  const image = isSubmedia
    ? data.eyecatch
    : `https://former-cdn.cinra.net/uploads/img/${data.img_path}`;
  const title = data.title;
  const client = data.client ?? null;
  const isPr = data.isPr;
  const contributor = isSubmedia
    ? data.author || null
    : data.contributors?.[0] || null;

  return (
    <li>
      <a href={url} className="p-articleCard">
        <div className="p-articleCard__image">
          <picture className="c-thumbnail">
            <img
              src={image}
              alt=""
              loading="lazy"
              decoding="async"
              className=""
            />
          </picture>
        </div>
        <div className="p-articleCard__text">
          {/* <b className="p-articleCard__group">{data.summary}</b> */}

          <p className="p-articleCard__title">
            <span>{title}</span>
          </p>
          {isPr && client && (
            <em className="p-articleCard__sponsor">Sponsored by {client}</em>
          )}

          <div className="p-articleCard__meta">
            {/* 著者(submedia) */}
            {contributor && isSubmedia && (
              <div className="p-articleCard__author">
                <span>{contributor}</span>
              </div>
            )}
            {/*  著者(新メディア) */}
            {contributor && !isSubmedia && (
              <div className="p-articleCard__author">
                {contributor.img_path_url && (
                  <img
                    src={contributor.img_path_url}
                    alt=""
                    loading="lazy"
                    decoding="async"
                    className=""
                  />
                )}
                <span>by {contributor.name}</span>
              </div>
            )}
          </div>
        </div>
      </a>
    </li>
  );
};

const $cinra = new API.CinraService();

const ArticleListTwoColumn = ({ slug }: ArticleListTwoColumnProps) => {
  const api = slug
    ? {
        endpoint: "/article/gettagposts",
        params: { slug },
      }
    : {
        endpoint: "/article/getposts",
        params: {},
      };

  // リスト
  const [items, setItems] = useState([]);

  // 全件取得、スピナーを非表示
  const [isDone, done] = useState(false);

  // API通信中か
  const fetching = useRef(false);

  // 記事の現在ページ
  const currentPage = useRef(2);

  // 記事の総件数
  const total = useRef(-1);

  // 表示件数
  const perPage = useRef(20);

  const fetch = () => {
    if (fetching.current) {
      return;
    }

    fetching.current = true;

    $cinra.$api
      .get(api.endpoint, {
        params: {
          ...api.params,
          page: currentPage.current,
        },
      })
      .then(({ data }: { data: any }) => {
        // @ts-ignore
        setItems((prev) => {
          return [...prev, ...data.data];
        });
        fetching.current = false;
        currentPage.current += 1;
        total.current = data.total;
        perPage.current = data.per_page;
      })
      .catch((error: any) => {
        console.error(error.response.data.message);
        done(true);
      });
  };

  // 最後まで取得したらスピナー非表示
  useEffect(() => {
    if (total.current > 0 && items.length + perPage.current >= total.current) {
      done(true);
    }
  }, [items]);

  return (
    <ul className="p-items__list--2col">
      {items.map((item) => {
        //@ts-ignore
        return <ArticleItem data={item} key={item.id} />;
      })}
      {!isDone && <Spinner handleFetch={fetch} />}
    </ul>
  );
};

export default ArticleListTwoColumn;
